<template>
  <v-app id="keep">
    <v-app-bar app color="blue">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="d-lg-none d-xl-none d-xs-block d-sm-block d-md-block white--text"
      ></v-app-bar-nav-icon>
      <span class="title ml-3 mr-5 d-none d-lg-block d-xl-block">
        <v-avatar size="60">
          <v-img src="@/assets/logo_lantas.png"></v-img>
        </v-avatar>
        <span class="font-weight-bold white--text">&nbsp;&nbsp;&nbsp;&nbsp; SIM Booster</span>
      </span>
      <span class="font-weight-bold white--text d-lg-none d-xl-none">&nbsp;&nbsp;&nbsp;&nbsp; SIM Booster</span>
      <v-spacer />
      <v-avatar size="50" class="d-lg-none d-xl-none">
          <v-img src="@/assets/logo_lantas.png"></v-img>
      </v-avatar>
      <v-btn dark text to="/" class="mr-2 d-none d-lg-flex d-xl-flex"> <v-icon left>fas fa-home</v-icon>Home</v-btn>
      <v-btn dark text to="/jadwals" class="mr-12 d-none d-lg-flex d-xl-flex "> <v-icon left>fas fa-id-card-alt</v-icon>Jadwal Antrian</v-btn>

      <v-btn class="d-none d-lg-flex d-xl-flex" :dark=" is_login ? false : true" :to=" is_login ? '/admin' : '/login'" :color="is_login ? 'grey lighten-5' : 'success'">
        {{ is_login ? 'Admin' : 'Login'}}
        <v-icon right>{{ is_login ? 'fas fa-users-cog' : 'fas fa-user-lock'}}</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      disable-resize-watcher
      temporary
    >
      <v-list
        dense
        nav
      >
        <v-list-item
          link
          to="/"
        >
          <v-list-item-icon>
            <v-icon>fas fa-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/jadwals"
        >
          <v-list-item-icon>
            <v-icon>fas fa-id-card-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Jadwal Antrian</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/login"
        >
          <v-list-item-icon>
            <v-icon>fas fa-user-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container>
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import utils from "../utils"
export default {
  props: {
    source: String,
  },
  data: (vm) => ({
    host: vm.$host,
    drawer: null,
  }),
  computed: {
    is_login() {
      return utils.session() !== null;
    }
  },
  methods: {},
  mounted() {},
};
</script>

<style>
#keep .v-navigation-drawer__border {
  display: none;
}

.company {
  height: 64px;
  width: 256px;
  position: fixed;
  background-color: #607d8b;
  color: white;
}
</style>