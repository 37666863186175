export default {
    items: (state) => {
        return state.items;
    },
    antrian_calls: (state) => {
        return state.antrian_calls;
    },
    antrian_fotos: (state) => {
        return state.antrian_fotos;
    },
    antrian_aviss: (state) => {
        return state.antrian_aviss;
    },
    alls: (state) => {
        return state.alls;
    },
    antrian_8: (state) => {
        return state.antrian_8;
    },
    antrian_9: (state) => {
        return state.antrian_9;
    },
    antrian_10: (state) => {
        return state.antrian_10;
    },
    antrian_11: (state) => {
        return state.antrian_11;
    },
    antrian_12: (state) => {
        return state.antrian_12;
    },
    antrian_13: (state) => {
        return state.antrian_13;
    },
    antrian_prints: (state) => {
        return state.antrian_prints;
    },
    item: (state) => (id) => {
        const item = state.items.find(item => item.id === id);
        return item;
    },
    item_print: (state) => (id) => {
        const item = state.antrian_prints.find(item => item.id === id);
        return item;
    },
    get_item: (state) => {
        return state.item;
    },
}