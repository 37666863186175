import defaultState from './antrian/state';
import getters from './antrian/getters';
import actions from './antrian/actions';
import mutations from './antrian/mutations';

export default {
    namespaced: true,
    state: defaultState,
    getters,
    actions,
    mutations,
}