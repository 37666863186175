import defaultState from './jadwal/state';
import getters from './jadwal/getters';
import actions from './jadwal/actions';
import mutations from './jadwal/mutations';

export default {
    namespaced: true,
    state: defaultState,
    getters,
    actions,
    mutations,
}