//import { union } from '../../utils';

export default {
    DATA: (state, data) => {
        state.data = data;
    },
    SET_ITEM: (state, item) => {
        state.item = item;
    },
    SET_MENITS: (state, menits) => {
        state.menits = menits;
    },
    SET_JAMS: (state, jams) => {
        state.jams = jams;
    },
    SET_IS_MENIT: (state, is_menit) => {
        state.is_menit = is_menit;
    }
}