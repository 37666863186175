/* eslint-disable */ 
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/swal';
import './plugins/toast';
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'

import '@/assets/style.css'

import axios from 'axios'
import progress from './plugins/progress_dialog';

import moment from 'moment';

import injector from 'vue-inject';

import {
  v1 as uuidv1
} from 'uuid';

import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

import eventBus from './eventbus'


if (localStorage.getItem("sim-booster:device_id") === null || localStorage.getItem("sim-booster:device_id") === "") {
  localStorage.setItem("sim-booster:device_id", uuidv1());
}

moment.locale("id")

Vue.use(progress)
Vue.use(injector)

const host = window.location.hostname
//const port = '80'
//const port = '1331'

//axios.defaults.baseURL = `http://${host}:${port}/api`
//axios.defaults.baseURL = `https://${host}/api`
axios.defaults.baseURL = `https://simbooster.polresjembrana.com/api`


function connect() {
  const ws = new WebSocket(`wss://simbooster.polresjembrana.com/ws`)
  //const ws = new WebSocket(`ws://${host}:${port}/ws`)

  ws.onopen = () => {
    console.log("Connected")
  }
  
  ws.onmessage = (msg) => {
    console.log(msg.data)
    const data = JSON.parse(msg.data)

    if (data.action === 'antrian') {
      eventBus.$emit('antrian', data.antrian)
    } else if (data.action === 'antrian_call') {
      eventBus.$emit('antrian_call', data.item)
    } else if (data.action === 'antrian_print') {
      eventBus.$emit('antrian_print', data.item)
    } else if (data.action === 'antrian_foto') {
      eventBus.$emit('antrian_foto', data.item)
    } else if (data.action === 'antrian_avis') {
      console.log(data.item)
      eventBus.$emit('antrian_avis', data.item)
    } else if (data.action === 'antrian_finish') {
      eventBus.$emit('antrian_finish', data.item)
    }

  }

  ws.onclose = function (e) {
    console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
    setTimeout(function () {
      connect();
    }, 1000);
  };

  ws.onerror = function (err) {
    console.error('Socket encountered error: ', err.message, 'Closing socket');
    ws.close();
  };
}

connect();



Vue.axios = Vue.prototype.$axios = axios
//Vue.host = Vue.prototype.$host = `http://${host}:${port}/`
Vue.host = Vue.prototype.$host = `https://${host}/`
//Vue.host_image = Vue.prototype.$host_image = `http://${host}:${port}/`
Vue.host_image = Vue.prototype.$host_image = `https://${host}/`

injector.constant('axios', axios)
injector.constant('router', router)

import Default from './layouts/default'
import Admin from './layouts/admin'
import Login from './layouts/login'
import NotFound from './layouts/notfound'

Vue.component('default-layout', Default)
Vue.component('notfound-layout', NotFound)
Vue.component('admin-layout', Admin)
Vue.component('login-layout', Login)

Vue.filter('str_limit', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + ' ....';
});

Vue.prototype.moment = moment

Vue.config.productionTip = false
Vue.mixin({
  methods: {
    breadcumText: (str) => {
      const text = str.replace("_", " ");
      //return text.charAt(0).toUpperCase() + text.slice(1)
      return text
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')