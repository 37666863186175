export default {
    items: [],
    display_items: [],
    item:{},
    prev_show: true,
    next_show: true,
    last_id: 0,
    search: "",
    filter_search: "",
    limit: {
        value: 25,
        text: '25'
    },
    nama: '',
    account: '',
    foto: '',
    hp: '',
}