export default {
    data: (state) => {
        return state.data;
    },
    item: (state) => {
        return state.item;
    },
    menits: (state) => {
        return state.menits;
    },
    jams: (state) => {
        return state.jams;
    },
    is_menit: (state) => {
        return state.is_menit;
    }
}