//import { union } from '../../utils';
import _ from 'lodash';

export default {
    SET_ITEMS: (state, items) => {
        state.items = items;
    },
    SET_ANTRIANS: (state, antrians) => {
        state.antrians = antrians;
    },
    SET_ALLS: (state, alls) => {
        state.alls = alls;
    },
    SET_ITEM: (state, item) => {
        state.item = item;
    },
    SET_ANTRIAN_CALLS: (state, antrians) => {
        //state.antrian_calls = antrians;

        state.antrian_8 = []
        state.antrian_9 = []
        state.antrian_10 = []
        state.antrian_11 = []
        state.antrian_12 = []
        state.antrian_13 = []

        antrians.forEach(item => {
            if (item.jam_between === "08:00 - 09:00") {
                state.antrian_8.push(item)
            } else if (item.jam_between === "09:00 - 10:00") {
                state.antrian_9.push(item)
            } else if (item.jam_between === "10:00 - 11:00") {
                state.antrian_10.push(item)
            } else if (item.jam_between === "11:00 - 12:00") {
                state.antrian_11.push(item)
            } else if (item.jam_between === "12:00 - 13:00") {
                state.antrian_12.push(item)
            } else if (item.jam_between === "13:00 - 14:00") {
                state.antrian_13.push(item)
            }
        });

    },
    SET_ANTRIAN_PRINTS: (state, antrians) => {
        state.antrian_prints = antrians;
    },
    SET_ANTRIAN_FOTOS: (state, antrians) => {
        state.antrian_fotos = antrians;
    },
    SET_ANTRIAN_AVISS: (state, antrians) => {
        state.antrian_aviss = antrians;
    },
    ADD_ANTRIAN_CALLS: (state, item) => {
        if (item.jam_between === "08:00 - 09:00") {

            let t_8 = false;
            state.antrian_8.forEach((it) => {
                if (item.id === it.id) {
                    t_8 = true
                }
            });

            if (t_8 === false) {
                state.antrian_8.push(item)
            }
            
        } else if (item.jam_between === "09:00 - 10:00") {
            let t_9 = false;
            state.antrian_9.forEach((it) => {
                if (item.id === it.id) {
                    t_9 = true
                }
            });

            if (t_9 === false) {
                state.antrian_9.push(item)
            }
            //state.antrian_9.push(item)
        } else if (item.jam_between === "10:00 - 11:00") {
            let t_10 = false;
            state.antrian_10.forEach((it) => {
                if (item.id === it.id) {
                    t_10 = true
                }
            });

            if (t_10 === false) {
                state.antrian_10.push(item)
            }
            //state.antrian_10.push(item)
        } else if (item.jam_between === "11:00 - 12:00") {
            let t_11 = false;
            state.antrian_11.forEach((it) => {
                if (item.id === it.id) {
                    t_11 = true
                }
            });

            if (t_11 === false) {
                state.antrian_11.push(item)
            }
            //state.antrian_11.push(item)
        } else if (item.jam_between === "12:00 - 13:00") {
            let t_12 = false;
            state.antrian_12.forEach((it) => {
                if (item.id === it.id) {
                    t_12 = true
                }
            });

            if (t_12 === false) {
                state.antrian_12.push(item)
            }
            //state.antrian_12.push(item)
        } else if (item.jam_between === "13:00 - 14:00") {
            let t_13 = false;
            state.antrian_13.forEach((it) => {
                if (item.id === it.id) {
                    t_13 = true
                }
            });

            if (t_13 === false) {
                state.antrian_13.push(item)
            }
            //state.antrian_13.push(item)
        }
        //state.antrian_calls.push(antrian);
    },
    ADD_ANTRIAN_PRINTS: (state, antrian) => {
        state.antrian_prints.push(antrian);
    },
    ADD_ANTRIAN_FOTOS: (state, antrian) => {
        state.antrian_fotos.push(antrian);
    },
    ADD_ANTRIAN_AVISS: (state, antrian) => {
        state.antrian_aviss.push(antrian);
    },
    REMOVE_ANTRIAN_CALLS: (state, item) => {

        if (item.jam_between === "08:00 - 09:00") {
            const items_8 =  _.reject(state.antrian_8, {
                id: item.id
            });
            state.antrian_8 = items_8
        } else if (item.jam_between === "09:00 - 10:00") {
            const items_9 =  _.reject(state.antrian_9, {
                id: item.id
            });
            state.antrian_9 = items_9
        } else if (item.jam_between === "10:00 - 11:00") {
            const items_10 =  _.reject(state.antrian_10, {
                id: item.id
            });
            state.antrian_10 = items_10
        } else if (item.jam_between === "11:00 - 12:00") {
            const items_11 =  _.reject(state.antrian_11, {
                id: item.id
            });
            state.antrian_11 = items_11
        } else if (item.jam_between === "12:00 - 13:00") {
            const items_12 =  _.reject(state.antrian_12, {
                id: item.id
            });
            state.antrian_12 = items_12
        } else if (item.jam_between === "13:00 - 14:00") {
            const items_13 =  _.reject(state.antrian_13, {
                id: item.id
            });
            state.antrian_13 = items_13
        }

        
    },
    UPDATE_ANTRIAN_CALLS: (state, item) => {

        if (item.jam_between === "08:00 - 09:00") {
            const items_8 =  _.map(state.antrian_8, it => {
                if (item.id === it.id) {
                    it.seri = item.seri
                    it.is_print = 1
                }

                return it;
            });
            state.antrian_8 = items_8
        } else if (item.jam_between === "09:00 - 10:00") {
            const items_9 =  _.map(state.antrian_9, it => {
                if (item.id === it.id) {
                    it.seri = item.seri
                    it.is_print = 1
                }

                return it;
            });
            state.antrian_9 = items_9
        } else if (item.jam_between === "10:00 - 11:00") {
            const items_10 =  _.map(state.antrian_10, it => {
                if (item.id === it.id) {
                    it.seri = item.seri
                    it.is_print = 1
                }

                return it;
            });
            state.antrian_10 = items_10
        } else if (item.jam_between === "11:00 - 12:00") {
            const items_11 =  _.map(state.antrian_11, it => {
                if (item.id === it.id) {
                    it.seri = item.seri
                    it.is_print = 1
                }

                return it;
            });
            state.antrian_11 = items_11
        } else if (item.jam_between === "12:00 - 13:00") {
            const items_12 =  _.map(state.antrian_12, it => {
                if (item.id === it.id) {
                    it.seri = item.seri
                    it.is_print = 1
                }

                return it;
            });
            state.antrian_12 = items_12
        } else if (item.jam_between === "13:00 - 14:00") {
            const items_13 =  _.map(state.antrian_13, it => {
                if (item.id === it.id) {
                    it.seri = item.seri
                    it.is_print = 1
                }

                return it;
            });
            state.antrian_13 = items_13
        }

        
    },
    REMOVE_ANTRIAN_PRINTS: (state, antrian) => {
        const items =  _.reject(state.antrian_prints, {
            id: antrian.id
        });
        state.antrian_prints = items
    },
    REMOVE_ANTRIAN_FOTOS: (state, antrian) => {
        const items =  _.reject(state.antrian_fotos, {
            id: antrian.id
        });
        state.antrian_fotos = items
    },
    REMOVE_ANTRIAN_AVISS: (state, antrian) => {
        const items =  _.reject(state.antrian_aviss, {
            id: antrian.id
        });
        state.antrian_aviss = items
    }
}