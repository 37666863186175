export default {
    items:[],
    item: {},
    antrian_calls: [],
    antrian_fotos: [],
    antrian_aviss: [],
    antrian_8: [],
    antrian_9: [],
    antrian_10: [],
    antrian_11: [],
    antrian_12: [],
    antrian_13: [],
    antrian_prints: [],
    alls:[],
}