import injector from 'vue-inject'
//import _ from 'lodash';

export default {
    create: injector.encase(['axios','router'], (axios, router) => (context, item) => {
        axios.post(`/antrian`, item, {
                message: "Antrian berhasil di booking"
            })
            .then((response) => {
                const item = response.data;
                context.commit('SET_ITEM', item)
                router.push(`/jadwal/${item.id}?home=true`)
            }).catch((error) => {
                console.log(error);
            })
    }),
    panggil: injector.encase(['axios','router'], (axios) => (context, item) => {
        axios.post(`/admin/panggil?no=${item.no}`, item, {
                message: "Antrian berhasil di panggil"
            })
            .then((response) => {
                const item = response.data;
                console.log(item)
            }).catch((error) => {
                console.log(error);
            })
    }),
    foto: injector.encase(['axios','router'], (axios) => (context, item) => {
        axios.post(`/admin/panggil_foto?no=${item.no}`, item, {
                message: "Antrian berhasil di panggil"
            })
            .then((response) => {
                const item = response.data;
                console.log(item)
            }).catch((error) => {
                console.log(error);
            })
    }),
    avis: injector.encase(['axios','router'], (axios) => (context, item) => {
        axios.post(`/admin/panggil_avis?no=${item.no}`, item, {
                message: "Antrian berhasil di panggil"
            })
            .then((response) => {
                const item = response.data;
                console.log(item)
            }).catch((error) => {
                console.log(error);
            })
    }),
    get_menits: injector.encase(['axios'], (axios) => (context, params) => {
        axios.get(`/jam?jenis_permohonan=${params.jenis_permohonan}&tanggal=${params.tanggal}&jam_between=${params.jam_between}`)
            .then((response) => {
                const items = response.data;
                //console.log(items);
                context.commit('SET_IS_MENIT', true);
                context.commit('SET_MENITS', items);
                
            })
    }),
    get_jams: injector.encase(['axios'], (axios) => (context, params) => {
        axios.get(`/cek/jam?tanggal=${params.tanggal}`)
            .then((response) => {
                const items = response.data;
                context.commit('SET_JAMS', items);
                
            })
    }),
    next: injector.encase(['router'], (router) => (context, data) => {
        context.commit('DATA', data);
        router.push(`antrian2?tipe=${data.jenis_permohonan}`)
    })
}