import injector from 'vue-inject'
//import _ from 'lodash';

export default {
    get_jadwals: injector.encase(['axios'], (axios) => (context, params) => {
        axios.get(`/antrian/${params.device_id}?tanggal=${params.tanggal}`)
            .then((response) => {
                const items = response.data;
                context.commit('SET_ITEMS', items);
            })
    }),
    get_jadwal: injector.encase(['axios'], (axios) => (context, id) => {
        axios.get(`/jadwal/${id}`)
            .then((response) => {
                const item = response.data;
                context.commit('SET_ITEM', item);
            })
    }),
    get_jadwal_antrian_calls: injector.encase(['axios'], (axios) => (context, params) => {

        console.log(params.status)
        
        axios.get(`/admin/antrian/status?tanggal=${params.tanggal}&status=${params.status}`)
            .then((response) => {
                const items = response.data;
                context.commit('SET_ANTRIAN_CALLS', items);
            })
    }),
    get_jadwal_antrian_fotos: injector.encase(['axios'], (axios) => (context, params) => {

        console.log(params.status)
        
        axios.get(`/admin/antrian/status?tanggal=${params.tanggal}&status=${params.status}`)
            .then((response) => {
                const items = response.data;
                context.commit('SET_ANTRIAN_FOTOS', items);
            })
    }),
    get_jadwal_antrian_aviss: injector.encase(['axios'], (axios) => (context, params) => {

        console.log(params.status)
        
        axios.get(`/admin/antrian/status?tanggal=${params.tanggal}&status=${params.status}`)
            .then((response) => {
                const items = response.data;
                console.log(items);
                context.commit('SET_ANTRIAN_AVISS', items);
            })
    }),
    get_jadwal_antrian_finish: injector.encase(['axios'], (axios) => (context, params) => {

        let url = ""
        
        if (params.filter === "undefined" || params.filter === null || params.filter === undefined) {
            url = `/admin/antrian/all?status=${params.status}`
        } else {
            url = `/admin/antrian/all/cari?status=${params.status}&filter=${params.filter}&cari=${params.cari}`
        }      
        axios.get(`${url}`)
            .then((response) => {
                const items = response.data;
                context.commit('SET_ALLS', items);
            })
    }),
    get_jadwal_antrian_lulus: injector.encase(['axios'], (axios) => (context, params) => {

        let url = ""
        
        if (params.filter === "undefined" || params.filter === null || params.filter === undefined) {
            url = `/admin/antrian/lulus/all`
        } else {
            url = `/admin/antrian/lulus/all/cari?filter=${params.filter}&cari=${params.cari}`
        }      
        axios.get(`${url}`)
            .then((response) => {
                const items = response.data;
                context.commit('SET_ALLS', items);
            })
    }),
    update_antrian_by_status: injector.encase(['axios'], (axios) => (context, item) => {
        return new Promise((resolve) => {
            axios.post(`/admin/antrian/update?kode=${item.kode}&status=${item.status}`, item, {
                message: "Antrian berhasil di update"
            })
            .then((response) => {
                const item = response.data;
                resolve(item)
            }).catch((error) => {
                console.log(error);
            })
        });
    }),
    next_antrian_to_foto: injector.encase(['axios'], (axios) => (context, item) => {
        return new Promise((resolve) => {
            axios.post(`/admin/antrian/update?kode=${item.kode}&status=${item.status}`, item, {
                message: "Antrian berhasil di update"
            })
            .then((response) => {
                const item = response.data;
                resolve(item)
            }).catch((error) => {
                console.log(error);
            })
        });
    }),
    finish_antrian: injector.encase(['axios'], (axios) => (context, item) => {
        return new Promise((resolve) => {
            axios.post(`/admin/antrian/update?kode=${item.kode}&status=${item.status}`, item, {
                message: "Antrian berhasil di update"
            })
            .then((response) => {
                const item = response.data;
                resolve(item)
            }).catch((error) => {
                console.log(error);
            })
        });
    }),
    update_antrian_by_seri: injector.encase(['axios'], (axios) => (context, item) => {
        return new Promise((resolve) => {
            axios.post(`/admin/antrian/seri`, item, {
                message: "Antrian berhasil di update"
            })
            .then((response) => {
                const item = response.data;
                resolve(item)
            }).catch((error) => {
                console.log(error);
            })
        });
    }),

}