import Vue from 'vue'
import Router from 'vue-router'
import utils from './utils'

Vue.use(Router)


let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: '/',
      name: 'home',
      meta: {
        layout: 'default'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/home.vue')
    },
    {
      path: '/pilihan',
      name: 'pilihan',
      meta: {
        layout: 'default'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/choice.vue')
    },
    {
      path: '/antrian1',
      name: 'antrian1',
      meta: {
        layout: 'default'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/antrian1.vue')
    },
    {
      path: '/antrian_panjang',
      name: 'antrian_panjang',
      meta: {
        layout: 'default'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/antrian_panjang.vue')
    },
    {
      path: '/antrian2',
      name: 'antrian2',
      meta: {
        layout: 'default'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/antrian2.vue')
    },
    {
      path: '/panggil_antrian',
      name: 'panggil_antrian',
      meta: {
        layout: 'default'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/panggil_antrian.vue')
    },
    {
      path: '/panggil_foto',
      name: 'panggil_foto',
      meta: {
        layout: 'default'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/panggil_foto.vue')
    },
    {
      path: '/panduan',
      name: 'panduan',
      meta: {
        layout: 'default'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/panduan.vue')
    },
    {
      path: '/informasi',
      name: 'informasi',
      meta: {
        layout: 'default'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/informasi.vue')
    },
    {
      path: '/jadwals',
      name: 'jadwal',
      meta: {
        layout: 'default'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/jadwal.vue')
    },
    {
      path: '/jadwal/:id',
      name: 'detail_jadwal',
      meta: {
        layout: 'default'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/detail_jadwal.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        layout: 'login'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/login.vue')
    },
    {
      path: '*',
      meta: {
        layout: 'notfound'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/notfound.vue')
    },
    {
      path: '/admin',
      name: 'admin',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/admin.vue'),
    },
    {
      path: '/admin/change',
      name: 'change',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/user/change.vue'),
    },
    {
      path: '/admin/account',
      name: 'account',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/user/account.vue'),
    },
    {
      path: '/admin/user',
      name: 'user',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/user/index.vue'),
    },
    {
      path: '/admin/user/create',
      name: 'create_user',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/user/add.vue'),
    },
    {
      path: '/admin/user/edit/:id',
      name: 'edit_user',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/user/edit.vue'),
    },
    {
      path: '/admin/user/reset/:id',
      name: 'reset_user',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/user/reset_password.vue'),
    },
    {
      path: '/admin/settings',
      name: 'wa',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/setting/wa.vue'),
    },
    {
      path: '/admin/antrian/panggil',
      name: 'panggil',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/antrian/panggil.vue'),
    },
    {
      path: '/admin/antrian/foto',
      name: 'foto',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/antrian/foto.vue'),
    },
    {
      path: '/admin/antrian/avis',
      name: 'avis',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/antrian/avis.vue'),
    },
    {
      path: '/admin/antrian/print',
      name: 'print',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/antrian/print.vue'),
    },
    {
      path: '/admin/antrian/tidak_lulus',
      name: 'print',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/antrian/tidak_lulus.vue'),
    },
    {
      path: '/admin/antrian/print/detail/:id',
      name: 'detail_print',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/antrian/detail_print.vue'),
    },
    {
      path: '/admin/jumlah/sim',
      name: 'jumlah_sim',
      meta: {
        requiresAuth: true,
        layout: 'admin'
      },
      component: () => import( /* webpackChunkName: "about" */ './views/antrian/jumlah_sim.vue'),
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (utils.session() === null) {
      next({
        path: '/login',
        params: {
          nextUrl: to.fullPath
        }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router;