import Vue from 'vue'
import Vuex from 'vuex'

import user from './store/user'
import constant from './store/constant';
import antrian from './store/antrian'
import jadwal from './store/jadwal'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        antrian,
        jadwal,
        constant,
    }
});